import { api } from '@/api'
import { logError } from '@/api/firebase'

// interface Record {
//     remainPoints: 0
//     spentPoints: 0
//     totalPoints: 0
// }
export const getPointRecord = async (userToken) => {
    const urlEnd = '/api/front/user/points/list'
    const body = { 'user_token': userToken }
    try {
        const response = await api.post(urlEnd, body)
        return response.data.data
    } catch (err) {
        console.error(err)
        logError({
            payload: body,
            error: err,
            apiUrl: urlEnd,
            method: 'post'
        })
        return Promise.reject('獲取會員點數發生錯誤')
    }
}

export const drawPoint = async (userToken) => {
    const urlEnd = '/api/front/user/points/change'
    const body = { 'user_token': userToken }
    try {
        const response = await api.post(urlEnd, body)
        return response.data.data.rewardName
    } catch (err) {
        console.error(err)
        logError({
            payload: body,
            error: err,
            apiUrl: urlEnd,
            method: 'post'
        })
        return Promise.reject('會員抽獎發生錯誤')
    }
}