<template>
  <page-layout>
    <div v-show="!isWaitingResult" class="personal-info">
      <divider-title title="請填寫個人資料" class="my-3"></divider-title>
      <section class="mb-3">
        <p>※中獎時將以此資料聯繫，</p>
        <p>務必填寫正確資料</p>
      </section>
      <input-field
        label="姓名*"
        placeholder="例：林美美"
        type="text"
        v-model="name"
        :errorMsg="validator.name ? '' : '請填寫中文姓名'"
      ></input-field>
      <input-field
        label="E-mail*"
        placeholder="例：123@gmail.com"
        type="text"
        v-model="email"
        :errorMsg="validator.email ? '' : '請輸入email'"
      ></input-field>
      <input-field
        label="手機*"
        placeholder="例：0912345678"
        type="text"
        v-model="cellphone"
        :errorMsg="validator.cellphone ? '' : '請輸入手機號碼'"
      ></input-field>
      <input-field
        label="稱謂*"
        placeholder="請選擇"
        type="select"
        :errorMsg="validator.gender ? '' : '請選擇稱謂'"
        :options="genderOptions"
        v-model="gender"
      ></input-field>
      <input-field
        label="出生日期*"
        placeholder="請選擇"
        type="date"
        min="1900-01-01"
        :max="birthdayMax"
        :errorMsg="validator.birthday ? '' : '請選擇出生日期'"
        v-model="birthday"
      ></input-field>
      <div class="agree mt-1 mb-3">
        <div
          class="agree__checkbox"
          :class="{ 'agree__checkbox--checked': isAgree }"
          @click="isAgree = !isAgree"
        />
        <label>
          <span @click="isAgree = !isAgree">我已詳閱、瞭解</span>
          <u @click="isAgreeModalShow = true">活動辦法、條款與約定</u>
        </label>
        <modal :open="isAgreeModalShow" @close="isAgreeModalShow = false">
          <divider-title
            title="活動辦法、條款與約定"
            class="my-3"
          ></divider-title>
          <p class="agree__policy">{{ texts.agreement }}</p>
          <button
            class="btn-primary"
            @click="
              isAgree = true;
              isAgreeModalShow = false;
            "
          >
            我同意
          </button>
        </modal>
      </div>
      <section>
        <button
          :disabled="Object.values(validator).some((b) => b === false)"
          class="btn-primary"
          @click="registerUser"
        >
          送出資料
        </button>
      </section>
    </div>
    <div class="waiting" v-show="isWaitingResult">
      <loading-circle title="上傳資料中"></loading-circle>
    </div>
  </page-layout>
</template>
<script>
import { mapActions } from "vuex";
import dayjs from "dayjs";
import PageLayout from "@/components/layout/PageLayout";
import DividerTitle from "@/components/common/DividerTitle";
import InputField from "@/components/common/InputField";
import Modal from "@/components/common/Modal";
import LoadingCircle from "@/components/common/LoadingCircle";
import texts from "@/assets/texts";
import { registerUser } from "@/api/user";
export default {
  components: {
    PageLayout,
    DividerTitle,
    InputField,
    Modal,
    LoadingCircle,
  },
  data() {
    return {
      texts: texts.personalInfo,
      name: "",
      email: "",
      gender: "",
      cellphone: "",
      birthday: "",
      genderOptions: [
        { key: "male", value: "1", text: "先生" },
        { key: "female", value: "2", text: "小姐" },
        { key: "none", value: "3", text: "不提供" },
      ],
      isAgreeModalShow: false,
      isAgree: false,
      isWaitingResult: false,
    };
  },
  computed: {
    validator() {
      return {
        name: this.name.length > 0,
        email: /^[A-Za-z0-9+_.-]+@(.+)$/.test(this.email),
        cellphone: /^[0-9]{10}$/.test(this.cellphone),
        gender: this.gender.length > 0,
        birthday:
          Date.now() - new Date(this.birthday) > 18 * 365 * 24 * 60 * 60 * 1000, // must be over 18
        isAgree: this.isAgree,
      };
    },
    birthdayMax() {
      return dayjs().subtract(18, "year").format("YYYY-MM-DD");
    },
  },
  methods: {
    ...mapActions({
      setUserHasRegistered: "user/setHasRegistered",
    }),
    async registerUser() {
      const formData = {
        name: this.name,
        gender: this.gender,
        birthday: dayjs(this.birthday).format("YYYY/MM/DD"),
        email: this.email,
        cellphone: this.cellphone,
      };
      try {
        this.isWaitingResult = true;
        await registerUser(this.$store.state.user.userToken, formData);
      } finally {
        this.isWaitingResult = false;
      }
      this.setUserHasRegistered(true);
      this.$router.push(this.$route.query.redirect);
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/scss/colors";
.personal-info {
  padding: 2rem 3.8rem;
  text-align: center;
}
.agree {
  display: flex;
  align-items: center;
  font-weight: 700;
  &__checkbox {
    width: 16px;
    height: 16px;
    border-radius: 50%;
    border: 1px solid $primary;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 5px;
    &--checked::after {
      content: "";
      width: 10px;
      height: 10px;
      display: block;
      border-radius: 50%;
      background-color: $primary;
    }
  }
  &__policy {
    white-space: pre-wrap;
    height: 210px;
    overflow: auto;
    margin-bottom: 3rem;
  }
}
.waiting {
  margin-top: 6.8rem;
}
</style>