<template>
  <page-layout>
    <div class="compaign-info">
      <div class="warpper">
        <section class="title">
          <h3>活動辦法</h3>
        </section>
        <section class="info">
          <ul class="p-2">
            <li>
              <p class="pb-1">
                <span class="info__title">活動時間：</span>
                <span>{{ texts.time }}</span>
              </p>
            </li>
            <li>
              <p class="pb-1">
                <span class="info__title">活動指定通路：</span>
                <span>{{ texts.channel }}</span>
              </p>
            </li>
            <li>
              <p class="pb-1">
                <span class="info__title">活動說明：</span>
                <span>{{ texts.description }}</span>
              </p>
            </li>
            <li>
              <div class="pb-3">
                <p>
                  <span class="info__title">活動獎項：</span>
                </p>
                <div class="prize">
                  <div class="prize__image mb-2">
                    <img
                      src="@/assets/images/prize-suitcase.png"
                      alt=""
                      :style="{ width: '110px' }"
                    />
                    <img
                      class="prize__image__illustration"
                      src="@/assets/images/illustration-vacation.png"
                      alt=""
                      :style="{
                        width: '85px',
                        right: '-90px',
                        top: '0px',
                      }"
                    />
                  </div>
                  <p class="prize__title mb-1">RIMOWA 登機箱</p>
                  <p class="prize__info">1 個（價值新台幣 17,900 元）：5名</p>
                </div>
                <div class="prize">
                  <div class="prize__image mb-2">
                    <img
                      src="@/assets/images/prize-hotel.png"
                      alt=""
                      :style="{ width: '120px' }"
                    />
                    <img
                      class="prize__image__illustration"
                      src="@/assets/images/illustration-ferry.png"
                      alt=""
                      :style="{
                        width: '100px',
                        left: '-100px',
                        bottom: '0px',
                      }"
                    />
                  </div>
                  <p class="prize__title mb-1">老爺酒店集團住宿券</p>
                  <p class="prize__info">2 張（價值新台幣 6,400 元）：30 名</p>
                </div>
                <div class="prize">
                  <div class="prize__image mb-2">
                    <img
                      src="@/assets/images/prize-goblet.png"
                      alt=""
                      :style="{ width: '90px' }"
                    />
                    <img
                      class="prize__image__illustration"
                      src="@/assets/images/illustration-train.png"
                      alt=""
                      :style="{
                        width: '110px',
                        right: '-90px',
                        bottom: '-35px',
                      }"
                    />
                  </div>
                  <p class="prize__title mb-1">蘇格登聞香杯</p>
                  <p class="prize__info">1 對（價值新台幣 400 元） ：120 名</p>
                </div>
              </div>
            </li>
            <li>
              <p class="pb-1">
                <span class="info__title">活動主（承）辦單位：</span>
                <span>{{ texts.host }}</span>
              </p>
            </li>
          </ul>
        </section>
        <section>
          <divider-title title="活動步驟" class="pt-3"> </divider-title>
          <div class="scroll my-3">
            <p>{{ texts.steps }}</p>
          </div>
        </section>
        <section>
          <divider-title title="注意事項" class="pt-3"> </divider-title>
          <div class="scroll my-3">
            <p>{{ texts.warning }}</p>
          </div>
        </section>
      </div>
      <div class="cta">
        <button class="btn-primary mb-1" @click="$router.push('/logging-main')">
          立即登錄發票
        </button>
        <button class="btn-primary-outline" @click="$router.push('/home-page')">
          回到首頁
        </button>
      </div>
    </div>
  </page-layout>
</template>
<script>
import PageLayout from "@/components/layout/PageLayout";
import DividerTitle from "@/components/common/DividerTitle";
import texts from "@/assets/texts/index";
export default {
  components: {
    PageLayout,
    DividerTitle,
  },
  data() {
    return {
      texts: texts.compaignInfo,
    };
  },
};
</script>
<style lang="scss" scoped>
@import "@/scss/colors";
@import "@/scss/fonts";
.compaign-info {
  padding: 2rem;
}
.warpper {
  border: 1px solid $primary;
  border-radius: 10px;
  background-color: $white;
  padding: 0.8rem 1.6rem;
  overflow: hidden;
}
.title {
  display: flex;
  margin: 0 auto;
  justify-content: center;
  align-items: center;
  color: $white;
  width: 206px;
  height: 71px;
  background: url("~@/assets/images/compaign-info-title-bg.png");
  background-repeat: no-repeat;
  @include serif;
}
.info {
  &__title {
    color: $primary;
    font-weight: 500;
  }
}
.scroll {
  overflow: auto;
  padding: 1.2rem 1.6rem;
  height: 312px;
  border-radius: 8px;
  box-shadow: inset 0px 0px 4px rgba(0, 0, 0, 0.25);
  white-space: pre-wrap;
}

.prize {
  font-weight: 700;
  text-align: center;
  margin-top: 2rem;
  &__image {
    position: relative;
    width: 155px;
    height: 155px;
    margin: 0 auto 2rem;
    border-radius: 50%;
    background: linear-gradient(#ca9251 0%, #f0b36c 100%, #ca9251 100%);
    display: flex;
    align-items: center;
    justify-content: center;
    &__illustration {
      position: absolute;
    }
  }

  &__title {
    color: $primary;
  }
}

.cta {
  padding: 2rem 5.4rem 0;
}
</style>