import { api } from '@/api'
import { logError } from '@/api/firebase'

const invoiceResponseHandler = (data) => {
    if (data.status === '審核通過') return 'success'
    if (data.failCode === "00001") return 'item-failed'
    if (data.failCode === "00002") return 'time-failed'
    if (data.failCode === "00003") return 'repeat-failed'
    if (data.failCode === "00004") return 'info-failed'
    if (data.failCode === "00005") return 'item-failed'
    if (data.status === '待審核' && data.type === '雲端載具') return 'pending-manual'
    if (data.status === '待審核' && data.type === '電子發票') return 'pending-data' // maybe never the case
    if (!data.failMessage) return 'success'
    // unknown case
    logError({
        payload: data,
        error: '',
        apiUrl: 'unknownInvioceResponse',
        method: ''
    })
    return ''
}

// interface Invoice{
//  failMessage: null
//  invoiceDate: "2022-03-17"
//  invoiceNumber: "XY03269352"
//  isDone: true
//  status: '審核通過'
// }
export const getInvoiceList = async (userToken) => {
    const urlEnd = '/api/front/user/invoices/list'
    const body = {
        'user_token': userToken
    }
    try {
        const response = await api.post(urlEnd, body)
        return response.data.data || []
    } catch (err) {
        console.error(err)
        logError({
            payload: body,
            error: err,
            apiUrl: urlEnd,
            method: 'post'
        })
        return Promise.reject('獲取發票列表發生錯誤')
    }
}

export const identifyEInvoice = async (userToken, image) => {
    const urlEnd = 'api/front/invoices/identify-electric-invoice'
    const body = {
        'user_token': userToken,
        'image': image
    }
    try {
        const response = await api.post(urlEnd, body)
        const { invoiceDate, invoiceNumber, randomCode, imageUrl } = response.data.data
        const payload = { invoiceDate, invoiceNumber, randomCode, imageUrl }
        return uploadEInvoice(userToken, payload)
    } catch (err) {
        console.error(err)
        logError({
            payload: { ...body, image: body.image.length },
            error: err,
            apiUrl: urlEnd,
            method: 'post'
        })
        return 'photo-failed'
        // return Promise.reject('辨識電子發票發生錯誤')
    }
}

// interfece payload {
//     "invoiceNumber":"XY03269352",
//     "invoiceDate":"2022-03-17",
//     "randomCode":"0330",
//     "imageUrl": ""
// }
export const uploadEInvoice = async (userToken, payload) => {
    const urlEnd = '/api/front/invoices/electric-invoices'
    const body = {
        'user_token': userToken,
        invoiceNumber: payload.invoiceNumber.toUpperCase(),
        invoiceDate: payload.invoiceDate,
        randomCode: payload.randomCode,
        imageUrl: payload.imageUrl || ''
    }
    try {
        const response = await api.post(urlEnd, body)
        return invoiceResponseHandler(response.data.data)
    } catch (err) {
        console.error(err)
        logError({
            payload: body,
            error: err,
            apiUrl: urlEnd,
            method: 'post'
        })
        return Promise.reject('電子發票輸入發生錯誤')
    }
}
// interfece payload {
//     "invoiceNumber":"XY03269352",
//     "invoiceDate":"2022-03-17",
//     "image":"imagebase64",
// }
export const uploadCloudCarrier = async (userToken, payload) => {
    const urlEnd = '/api/front/invoices/cloud-invoices'
    const body = {
        'user_token': userToken,
        invoiceNumber: payload.invoiceNumber.toUpperCase(),
        invoiceDate: payload.invoiceDate,
        image: payload.image
    }
    try {
        const response = await api.post(urlEnd, body)
        return invoiceResponseHandler(response.data.data)
    } catch (err) {
        console.error(err)
        logError({
            payload: { ...body, image: body.image.length },
            error: err,
            apiUrl: urlEnd,
            method: 'post'
        })
        return Promise.reject('雲端發票輸入發生錯誤')
    }
}