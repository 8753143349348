<template>
  <div class="loading-circle">
    <img
      src="@/assets/images/loading-circle.svg"
      class="loading-circle__image"
      alt="loading"
      width="52"
      height="52"
    />
    <h4 v-if="title" class="mt-4">{{ title }}</h4>
  </div>
</template>
<script>
export default {
  props: {
    title: {
      type: String,
      default: "",
    },
  },
};
</script>
<style lang="scss" scope>
.loading-circle {
  text-align: center;
  &__image {
    animation: spin 1s infinite steps(9);
  }
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>