<template>
  <div class="custom-select">
    <div
      class="select"
      ref="select"
      tabindex="0"
      @click="focusing = true"
      @keydown.enter="focusing = true"
    >
      <span>{{ selectedOption.text }} </span>
      <img src="@/assets/images/symbol-down.svg" alt="" width="13" />
    </div>
    <div v-show="focusing" class="mask"></div>
    <div
      class="options"
      ref="options"
      v-show="focusing"
      tabindex="-1"
      @focusout="focusing = false"
    >
      <div
        v-for="option in options"
        class="options__item"
        :class="{
          'options__item--active': selectedOption.value === option.value,
        }"
        tabindex="-1"
        :key="option.key"
        :value="option.value"
        @mousedown="
          $emit('change', option);
          focusing = false;
        "
      >
        {{ option.text }}
      </div>
    </div>
  </div>
</template>
<script>
export default {
  model: {
    prop: "value",
    event: "change",
  },
  data() {
    return {
      focusing: false,
    };
  },
  props: {
    selectedOption: {
      type: Object,
      default: () => ({}),
    },
    options: {
      type: Array,
      default: () => [],
    },
  },
  watch: {
    focusing: {
      async handler(value) {
        if (value === true) {
          await this.$nextTick();
          const { height, y } = this.$refs.select.getBoundingClientRect();
          const remainHeight = window.innerHeight - (height + y);
          this.$refs.options.style.maxHeight = remainHeight + "px";
          this.$refs.options.focus();
        }
      },
    },
  },
};
</script>
<style lang="scss" scope>
@import "@/scss/colors";
.custom-select {
  width: 100%;
  position: relative;
}
.select {
  display: flex;
  justify-content: space-between;
  padding: 10px;
  margin: 2px 0px;
  height: 43px;
  border-radius: 8px;
  background-color: $white;
  border: 1px solid $primary;
}
.options {
  background-color: $white;
  position: absolute;
  z-index: 1;
  top: 45px;
  border-radius: 8px;
  overflow: scroll;
  width: 100%;
  font-size: 1.4rem;
  &__item {
    padding: 1rem 5rem;
    cursor: pointer;
    &:hover {
      background-color: #eee;
    }
    &--active {
      color: $primary;
    }
    & + & {
      border-top: 1px solid #ddd;
    }
  }
}
.mask {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
}
</style>