<template>
  <portal to="modal">
    <div v-if="open" class="mask" @click.self="$emit('close')">
      <div class="wrapper">
        <slot></slot>
      </div>
    </div>
  </portal>
</template>
<script>
export default {
  props: {
    open: {
      type: Boolean,
      required: true,
    },
  },
};
</script>
<style lang="scss" scope>
@import "@/scss/colors";
.mask {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba($color: #000000, $alpha: 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
}
.wrapper {
  margin: 2rem;
  padding: 1.8rem;
  width: 100%;
  min-height: 100px;
  max-height: 90%;
  border-radius: 5px;
  box-shadow: 0px 0px 5px #000;
  background-color: $white;
  overflow: auto;
}
</style>