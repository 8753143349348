export const getBase64 = (file) => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.onloadend = () => resolve(reader.result);
        reader.onerror = reject
        reader.readAsDataURL(file);

    })
}

export const verifyInvoice = (invoiceNumber) => /^[a-zA-Z]{2}[0-9]{8}$/.test(invoiceNumber)