<template>
  <page-layout>
    <div class="lucky-draw">
      <divider-title title="我要抽獎" class="my-3"></divider-title>
      <section class="mb-3">
        <p>
          您尚有 <span class="number">{{ remainPoints }}</span> 次抽獎機會
        </p>
        <p>
          已抽了 <span class="number">{{ spentPoints }}</span> 次
        </p>
      </section>
      <section class="mx-2">
        <button
          class="btn-primary mb-1"
          :disabled="remainPoints === 0"
          @click="drawPoint"
        >
          立即抽獎
        </button>
        <button class="btn-primary-outline" @click="$router.push('/home-page')">
          回到首頁
        </button>
      </section>
      <modal :open="isModalShow" @close="isModalShow = false">
        <div class="modal">
          <divider-title
            :title="reward ? '恭喜中獎' : '再接再厲'"
            class="my-3"
          ></divider-title>
          <p v-if="reward">恭喜您</p>
          <p v-if="reward">獲得{{ reward }}獎項</p>
          <p v-if="!reward" class="">好可惜，這次沒中獎</p>
          <p v-if="!reward" class="mb-5">別氣餒，再接再厲！</p>
          <button class="btn-primary m-2" @click="isModalShow = false">
            關閉視窗
          </button>
        </div>
      </modal>
    </div>
  </page-layout>
</template>
<script>
import PageLayout from "@/components/layout/PageLayout";
import DividerTitle from "@/components/common/DividerTitle";
import Modal from "@/components/common/Modal";
import { getPointRecord, drawPoint } from "@/api/point";
export default {
  components: {
    PageLayout,
    DividerTitle,
    Modal,
  },
  mounted() {
    this.updatePoint();
  },
  data() {
    return {
      remainPoints: 0,
      spentPoints: 0,
      isModalShow: false,
      reward: null,
    };
  },
  methods: {
    async drawPoint() {
      this.reward = await drawPoint(this.$store.state.user.userToken);
      this.isModalShow = true;
      this.updatePoint();
    },
    async updatePoint() {
      const response = await getPointRecord(this.$store.state.user.userToken);
      this.remainPoints = response.remainPoints;
      this.spentPoints = response.spentPoints;
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/scss/colors";
.lucky-draw {
  padding: 2rem 3.8rem;
  text-align: center;
}
.number {
  color: $primary;
}
.modal {
  display: flex;
  flex-direction: column;
  align-items: center;
}
</style>
