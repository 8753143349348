<template>
  <page-layout>
    <div class="logging-record">
      <divider-title title="發票登錄進度查詢" class="my-3"></divider-title>
      <section class="mb-3">
        <p>蘇格登產品購買累積</p>
        <p>
          累計登錄發票張數
          <span class="number">{{ loggingRecords.length }}</span> 張
        </p>
      </section>
      <section v-if="loggingRecords.length" class="scroll mb-3">
        <div v-for="record in loggingRecords" :key="record.id" class="record">
          <img
            v-if="record.status === '審核通過'"
            src="@/assets/images/verified.png"
            alt=""
            width="39"
            height="54"
          />
          <img
            v-else-if="record.status === '待審核'"
            src="@/assets/images/verifying.png"
            alt=""
            width="39"
            height="54"
          />
          <img
            v-else-if="
              record.status === '資格不符' || record.status === '審核不通過'
            "
            src="@/assets/images/unqualified.png"
            alt=""
            width="39"
            height="54"
          />
          <div class="record__info">
            <p>發票號碼：{{ record.invoiceNumber }}</p>
            <p>登入日期：{{ record.invoiceDate }}</p>
            <p v-if="record.failMessage">失敗原因：{{ record.failMessage }}</p>
          </div>
        </div>
      </section>
      <section class="mx-2">
        <button class="btn-primary mb-1" @click="$router.push('/logging-main')">
          立即登錄發票
        </button>
        <button class="btn-primary-outline" @click="$router.push('/home-page')">
          回到首頁
        </button>
      </section>
    </div>
  </page-layout>
</template>
<script>
import PageLayout from "@/components/layout/PageLayout";
import DividerTitle from "@/components/common/DividerTitle";
import { getInvoiceList } from "@/api/invoice";
export default {
  components: {
    PageLayout,
    DividerTitle,
  },
  mounted() {
    this.getInvoiceList();
    // todo: remove below after api is ready
    // this.loggingRecords = [
    //   {
    //     invoiceNumber: "AB123456789",
    //     invoiceDate: "2020-03-07",
    //     status: "審核通過",
    //     isDone: true,
    //   },
    //   {
    //     invoiceNumber: "AB123456788",
    //     invoiceDate: "2020-03-07",
    //     status: "資格不符",
    //     failMessage: "未購買活動產品",
    //     isDone: true,
    //   },
    //   {
    //     invoiceNumber: "AB123456787",
    //     invoiceDate: "2020-03-07",
    //     status: "審核中",
    //     isDone: false,
    //   },
    // ];
  },
  data() {
    return {
      loggingRecords: [],
    };
  },
  methods: {
    async getInvoiceList() {
      const response = await getInvoiceList(this.$store.state.user.userToken);
      this.loggingRecords = response;
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/scss/colors";
.logging-record {
  padding: 2rem 3.8rem;
  text-align: center;
}
.number {
  color: $primary;
}
.scroll {
  overflow: auto;
  padding: 1.2rem 1.6rem;
  max-height: 246px;
  border-radius: 8px;
  box-shadow: inset 0px 0px 4px rgba(0, 0, 0, 0.25);
  background-color: $white;
}
.record {
  display: flex;
  padding: 1.2rem 2.4rem;
  align-items: center;
  border-bottom: 1px solid #ccc;
  &__info {
    margin-left: 1.8rem;
    color: rgba($color: #000000, $alpha: 0.6);
    text-align: left;
  }
}
</style>
