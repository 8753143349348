<template>
  <page-layout>
    <div class="cloud-carrier-logging">
      <divider-title title="雲端載具" class="my-3"></divider-title>
      <p class="mb-3">*若發票類型錯誤，將不具抽獎及集點資格</p>
      <div class="mb-5 px-2">
        <button
          class="btn-primary mb-1"
          @click="$router.push('/cloud-carrier-logging-upload')"
        >
          登錄載具發票資訊
        </button>
        <button class="btn-primary mb-1" @click="$router.push('/logging-main')">
          重新選擇登錄發票類型
        </button>
        <button
          class="btn-primary-outline mb-1"
          @click="$router.push('/home-page')"
        >
          回到首頁
        </button>
      </div>
      <divider-title title="雲端載具範本如下"></divider-title>
      <div class="smaple">
        <img
          src="@/assets/images/cloud-carrier-sample.png"
          alt=""
          width="252"
          height="297"
        />
      </div>
    </div>
  </page-layout>
</template>
<script>
import PageLayout from "@/components/layout/PageLayout";
import DividerTitle from "@/components/common/DividerTitle";
export default {
  components: {
    PageLayout,
    DividerTitle,
  },
};
</script>
<style lang="scss" scoped>
@import "@/scss/colors";
.cloud-carrier-logging {
  display: flex;
  flex-direction: column;
  padding: 2rem 3.8rem;
  text-align: center;
}

.smaple {
  margin-top: 2.8rem;
}
</style>