<template>
  <div class="input-field">
    <label v-if="label" for="">{{ label }}</label>
    <input
      v-if="type === 'text' || type === 'date'"
      class="input"
      :type="type"
      :value="value"
      :placeholder="placeholder"
      @input="$emit('change', $event.target.value)"
      @change="edited = true"
      v-bind="$attrs"
    />
    <custom-select
      v-else-if="type === 'select'"
      :options="options"
      :selectedOption="options.find((option) => option.value === value)"
      @change="
        $emit('change', $event.value);
        edited = true;
      "
    >
    </custom-select>
    <span class="error">{{ (edited ? errorMsg : "") || "&nbsp;" }}</span>
  </div>
</template>
<script>
import CustomSelect from "@/components/common/CustomSelect";
export default {
  components: {
    CustomSelect,
  },
  model: {
    prop: "value",
    event: "change",
  },
  props: {
    value: {
      type: String,
      default: "",
    },
    placeholder: {
      type: String,
      default: "",
    },
    label: {
      type: String,
      default: "",
    },
    errorMsg: {
      type: String,
      default: "",
    },
    type: {
      type: String,
      required: true,
      validator(value) {
        return ["text", "select", "date"].includes(value);
      },
    },
    options: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      edited: false,
    };
  },
};
</script>
<style lang="scss" scoped>
@import "@/scss/colors";
.input-field {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
label {
  font-weight: bold;
}
.input {
  border: 1px solid $primary;
  border-radius: 8px;
  width: 100%;
  padding: 10px;
  margin: 2px 0px;
  background-color: $white;
}
.error {
  color: $primary;
  align-self: flex-end;
  font-size: 1.2rem;
}
</style>
