<template>
  <home-page-layout>
    <div class="home-page">
      <button class="btn-primary mb-1" @click="$router.push('/compaign-info')">
        活動辦法
      </button>
      <button class="btn-primary mb-1" @click="$router.push('/logging-main')">
        立即登錄發票
      </button>
      <button class="btn-primary mb-1" @click="$router.push('/logging-record')">
        登錄進度查詢
      </button>
      <button class="btn-primary mb-1" @click="$router.push('/lucky-draw')">
        我要抽獎
      </button>
    </div>
  </home-page-layout>
</template>
<script>
import HomePageLayout from "@/components/layout/HomePageLayout";
export default {
  components: {
    HomePageLayout,
  },
};
</script>
<style lang="scss">
.home-page {
  display: flex;
  flex-direction: column;
  padding: 2rem 5.4rem;
}
</style>