import { initializeApp } from 'firebase/app';
import { getDatabase, ref, set } from "firebase/database";
import server from '@/constants/server'

const firebaseConfig = {
    apiKey: "AIzaSyCzlEsTN-o7TZmD7aSFgJ8HdAvyf34ncok",
    authDomain: "singleton-api.firebaseapp.com",
    databaseURL: "https://singleton-api-default-rtdb.firebaseio.com",
    projectId: "singleton-api",
    storageBucket: "singleton-api.appspot.com",
    messagingSenderId: "811081951646",
    appId: "1:811081951646:web:e8e8323306f5b6331c8e8f",
    measurementId: "G-2WZKE4NGEL"
};


const app = initializeApp(firebaseConfig);
const database = getDatabase(app);
const getErrorMsg = (error) => {
    if (error == null) return null
    if (typeof error.response.data.msg !== 'undefined') return error.response.data.msg
    if (typeof error.response.data.data.message !== 'undefined') return error.response.data.data.message
    if (error.message) return error.message
    return
}

export const logError = ({
    payload = '',
    error = '',
    apiUrl = '',
    method = 'get' }) => {
    try {
        const date = new Date().toDateString()
        const time = new Date().toTimeString().slice(0, 9)
        const api = encodeURIComponent(apiUrl)
        const serverUrl = encodeURIComponent(server.SERVER_URL)
        const data = JSON.stringify(payload)
        set(ref(database, `api/${date}/${api}/${time}`), {
            server: serverUrl,
            data,
            api,
            time,
            method,
            msg: getErrorMsg(error)
        });
    } catch (err) {
        // do nothing
    }
}

