var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('page-layout',[_c('div',{staticClass:"compaign-info"},[_c('div',{staticClass:"warpper"},[_c('section',{staticClass:"title"},[_c('h3',[_vm._v("活動辦法")])]),_c('section',{staticClass:"info"},[_c('ul',{staticClass:"p-2"},[_c('li',[_c('p',{staticClass:"pb-1"},[_c('span',{staticClass:"info__title"},[_vm._v("活動時間：")]),_c('span',[_vm._v(_vm._s(_vm.texts.time))])])]),_c('li',[_c('p',{staticClass:"pb-1"},[_c('span',{staticClass:"info__title"},[_vm._v("活動指定通路：")]),_c('span',[_vm._v(_vm._s(_vm.texts.channel))])])]),_c('li',[_c('p',{staticClass:"pb-1"},[_c('span',{staticClass:"info__title"},[_vm._v("活動說明：")]),_c('span',[_vm._v(_vm._s(_vm.texts.description))])])]),_c('li',[_c('div',{staticClass:"pb-3"},[_c('p',[_c('span',{staticClass:"info__title"},[_vm._v("活動獎項：")])]),_c('div',{staticClass:"prize"},[_c('div',{staticClass:"prize__image mb-2"},[_c('img',{style:({ width: '110px' }),attrs:{"src":require("@/assets/images/prize-suitcase.png"),"alt":""}}),_c('img',{staticClass:"prize__image__illustration",style:({
                      width: '85px',
                      right: '-90px',
                      top: '0px',
                    }),attrs:{"src":require("@/assets/images/illustration-vacation.png"),"alt":""}})]),_c('p',{staticClass:"prize__title mb-1"},[_vm._v("RIMOWA 登機箱")]),_c('p',{staticClass:"prize__info"},[_vm._v("1 個（價值新台幣 17,900 元）：5名")])]),_c('div',{staticClass:"prize"},[_c('div',{staticClass:"prize__image mb-2"},[_c('img',{style:({ width: '120px' }),attrs:{"src":require("@/assets/images/prize-hotel.png"),"alt":""}}),_c('img',{staticClass:"prize__image__illustration",style:({
                      width: '100px',
                      left: '-100px',
                      bottom: '0px',
                    }),attrs:{"src":require("@/assets/images/illustration-ferry.png"),"alt":""}})]),_c('p',{staticClass:"prize__title mb-1"},[_vm._v("老爺酒店集團住宿券")]),_c('p',{staticClass:"prize__info"},[_vm._v("2 張（價值新台幣 6,400 元）：30 名")])]),_c('div',{staticClass:"prize"},[_c('div',{staticClass:"prize__image mb-2"},[_c('img',{style:({ width: '90px' }),attrs:{"src":require("@/assets/images/prize-goblet.png"),"alt":""}}),_c('img',{staticClass:"prize__image__illustration",style:({
                      width: '110px',
                      right: '-90px',
                      bottom: '-35px',
                    }),attrs:{"src":require("@/assets/images/illustration-train.png"),"alt":""}})]),_c('p',{staticClass:"prize__title mb-1"},[_vm._v("蘇格登聞香杯")]),_c('p',{staticClass:"prize__info"},[_vm._v("1 對（價值新台幣 400 元） ：120 名")])])])]),_c('li',[_c('p',{staticClass:"pb-1"},[_c('span',{staticClass:"info__title"},[_vm._v("活動主（承）辦單位：")]),_c('span',[_vm._v(_vm._s(_vm.texts.host))])])])])]),_c('section',[_c('divider-title',{staticClass:"pt-3",attrs:{"title":"活動步驟"}}),_c('div',{staticClass:"scroll my-3"},[_c('p',[_vm._v(_vm._s(_vm.texts.steps))])])],1),_c('section',[_c('divider-title',{staticClass:"pt-3",attrs:{"title":"注意事項"}}),_c('div',{staticClass:"scroll my-3"},[_c('p',[_vm._v(_vm._s(_vm.texts.warning))])])],1)]),_c('div',{staticClass:"cta"},[_c('button',{staticClass:"btn-primary mb-1",on:{"click":function($event){return _vm.$router.push('/logging-main')}}},[_vm._v(" 立即登錄發票 ")]),_c('button',{staticClass:"btn-primary-outline",on:{"click":function($event){return _vm.$router.push('/home-page')}}},[_vm._v(" 回到首頁 ")])])])])}
var staticRenderFns = []

export { render, staticRenderFns }