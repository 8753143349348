<template>
  <h3>
    {{ title }}
  </h3>
</template>
<script>
export default {
  name: "DividerTitle",
  props: {
    title: {
      type: String,
      default: "",
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/scss/colors";
@import "@/scss/fonts";
h3 {
  display: flex;
  align-self: stretch;
  justify-content: space-between;
  align-items: center;
  color: $primary;
  font-weight: 700;
  @include serif;
}
h3::before,
h3::after {
  content: "";
  display: block;
  height: 1px;
  width: 60px;
  background: linear-gradient(
    to right,
    #9a1e3a00 0%,
    #9a1e3a 50%,
    #9a1e3a00 100%
  );
}
</style>
