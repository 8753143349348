<template>
  <div id="app">
    <router-view />
    <portal-target name="modal"></portal-target>
  </div>
</template>
<script>
import liff from "@line/liff";
import { markLogin, checkRegistered } from "@/api/user";
import { mapActions } from "vuex";
export default {
  name: "App",
  async created() {
    var sUserAgent = navigator.userAgent.toLowerCase();
    var bIsIpad = sUserAgent.match(/ipad/i) == "ipad";
    var bIsIphoneOs = sUserAgent.match(/iphone os/i) == "iphone os";
    var bIsMidp = sUserAgent.match(/midp/i) == "midp";
    var bIsUc7 = sUserAgent.match(/rv:1.2.3.4/i) == "rv:1.2.3.4";
    var bIsUc = sUserAgent.match(/ucweb/i) == "ucweb";
    var bIsAndroid = sUserAgent.match(/android/i) == "android";
    var bIsCE = sUserAgent.match(/windows ce/i) == "windows ce";
    var bIsWM = sUserAgent.match(/windows mobile/i) == "windows mobile";
    if (
      bIsIpad ||
      bIsIphoneOs ||
      bIsMidp ||
      bIsUc7 ||
      bIsUc ||
      bIsAndroid ||
      bIsCE ||
      bIsWM
    ) {
      await this.initLine();
      await this.markLogin();
      await this.checkRegistered();
      this.$router.push("home-page"); // after line inited display home page
    } else {
      alert("此活動不支援桌上型裝置");
      window.location.href = process.env.VUE_APP_LINE_FRIEND_LINK;
    }
  },
  methods: {
    ...mapActions({
      setUserToken: "user/setToken",
      setUserHasRegistered: "user/setHasRegistered",
    }),
    async initLine() {
      await liff.init({ liffId: process.env.VUE_APP_LIFFID });
      if (!liff.isLoggedIn()) {
        liff.login({ redirectUri: window.location.href });
        return Promise.reject("not logged in");
      }
      const data = await liff.getFriendship();
      if (data.friendFlag) await this.liffGetProfile();
      else this.linkToAddFriend();
    },
    async liffGetProfile() {
      const profile = await liff.getProfile();
      this.setUserToken(profile.userId);
    },
    async linkToAddFriend() {
      window.location.replace(process.env.VUE_APP_LINE_FRIEND_LINK);
    },
    async markLogin() {
      await markLogin(this.$store.state.user.userToken).catch(console.error);
    },
    async checkRegistered() {
      const result = await checkRegistered(
        this.$store.state.user.userToken
      ).catch(() => false);
      this.setUserHasRegistered(result);
    },
  },
};
</script>

<style lang="scss"></style>
