<template>
  <page-layout>
    <div class="logging-main">
      <divider-title title="請選擇登錄類型" class="my-3"></divider-title>
      <div class="px-2">
        <section class="pt-1">
          <button
            class="btn-primary mb-1"
            @click="chooseType = chooseTypes.eInvoice"
          >
            電子發票
          </button>
          <button
            class="btn-primary"
            @click="chooseType = chooseTypes.cloudCarrier"
          >
            雲端載具
          </button>
        </section>
        <section class="mt-5 pt-1">
          <button
            class="btn-primary-outline"
            @click="$router.push('/home-page')"
          >
            回到首頁
          </button>
        </section>
        <modal :open="chooseType === chooseTypes.eInvoice">
          <divider-title title="電子發票" class="my-3"></divider-title>
          <div class="modal-description">
            <p>
              財政部電子發票資料更新時間約為消費後6~48小時。待審核成功，將會發送通知提醒您進行抽獎。
            </p>
            <p>*如發票不符活動規則，將不另行通知，請至「登錄紀錄」頁面查詢。</p>
          </div>
          <div class="px-2">
            <button
              class="btn-primary mb-1"
              @click="$router.push('/e-invoice-logging')"
            >
              立即登錄發票
            </button>
            <button
              class="btn-primary-outline"
              @click="chooseType = chooseTypes.none"
            >
              關閉視窗
            </button>
          </div>
        </modal>
        <modal :open="chooseType === chooseTypes.cloudCarrier">
          <divider-title title="雲端載具" class="my-3"></divider-title>
          <div class="modal-description">
            <p>提醒：</p>
            <p>雲端載具採人工審核，</p>
            <p>至多需5-7個工作天進行審核作業</p>
          </div>
          <div class="px-2">
            <button
              class="btn-primary mb-1"
              @click="$router.push('/cloud-carrier-logging')"
            >
              立即登錄發票
            </button>
            <button
              class="btn-primary-outline"
              @click="chooseType = chooseTypes.none"
            >
              關閉視窗
            </button>
          </div>
        </modal>
      </div>
    </div>
  </page-layout>
</template>
<script>
import PageLayout from "@/components/layout/PageLayout";
import DividerTitle from "@/components/common/DividerTitle";
import Modal from "@/components/common/Modal";
const chooseTypes = {
  none: "none",
  eInvoice: "e-invoice",
  cloudCarrier: "cloud-cariier",
};
export default {
  components: {
    PageLayout,
    DividerTitle,
    Modal,
  },
  data() {
    return {
      loggingRecords: [],
      chooseTypes,
      chooseType: chooseTypes.none,
    };
  },
};
</script>
<style lang="scss" scoped>
.logging-main {
  padding: 2rem 3.8rem;
}
.modal-description {
  text-align: center;
  margin-bottom: 4rem;
}
</style>