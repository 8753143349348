<template>
  <page-layout>
    <div v-if="!isWaitingResult" class="cloud-carrier-loggin-upload">
      <divider-title title="雲端載具" class="title"></divider-title>
      <div class="px-2 upload">
        <input-field
          type="text"
          class="mb-1"
          label="發票號碼"
          placeholder="例：AB12345678"
          :errorMsg="isInvoiceNumberError ? '輸入格式為英文2碼數字8碼' : ''"
          v-model="invoiceNumber"
        ></input-field>
        <input-field
          type="date"
          class="mb-1"
          label="發票日期"
          :errorMsg="isInvoiceDateError ? '請選擇發票日期' : ''"
          v-model="invoiceDate"
        ></input-field>
        <label class="upload__label" for="">上傳發票照片</label>
        <button class="btn-primary mb-1" @click="$refs.inputFile.click()">
          選擇載具截圖或明細照片
        </button>
        <input
          type="file"
          hidden
          ref="inputFile"
          accept="image/*"
          @change="chooseFile"
        />
        <img
          v-if="imageBase64.length"
          class="upload__preview my-1"
          :src="imageBase64"
          alt=""
        />
        <p class="mb-3">
          ＊上傳之照片須清楚包含「購買日期、發票號碼、蘇格登產品名稱與金額」，若資訊不足導致無法判斷，將需重新上傳進行審核
        </p>
        <button
          class="btn-primary-outline"
          :disabled="
            isInvoiceNumberError ||
            isInvoiceDateError ||
            imageBase64.length === 0
          "
          @click="uploadCloudCarrier"
        >
          送出登錄資料
        </button>
      </div>
    </div>
    <div class="waiting" v-if="isWaitingResult">
      <loading-circle title="發票辨識中...請稍候"></loading-circle>
    </div>
  </page-layout>
</template>
<script>
import PageLayout from "@/components/layout/PageLayout";
import DividerTitle from "@/components/common/DividerTitle";
import InputField from "@/components/common/InputField";
import LoadingCircle from "@/components/common/LoadingCircle";
import { verifyInvoice, getBase64 } from "@/utils";
import { uploadCloudCarrier } from "@/api/invoice";
export default {
  components: {
    PageLayout,
    DividerTitle,
    InputField,
    LoadingCircle,
  },
  data() {
    return {
      invoiceNumber: "",
      invoiceDate: "",
      imageBase64: "",
      isWaitingResult: false,
    };
  },
  computed: {
    isInvoiceNumberError() {
      return !verifyInvoice(this.invoiceNumber);
    },
    isInvoiceDateError() {
      return this.invoiceDate.length === 0;
    },
  },
  methods: {
    chooseFile(e) {
      if (e.target.files.length === 0) {
        this.imageBase64 = "";
        return;
      }
      const imageFile = e.target.files[0];
      getBase64(imageFile).then((base64) => (this.imageBase64 = base64));
    },
    async uploadCloudCarrier() {
      const payload = {
        invoiceNumber: this.invoiceNumber,
        invoiceDate: this.invoiceDate,
        image: this.imageBase64,
      };
      try {
        this.isWaitingResult = true;
        const result = await uploadCloudCarrier(
          this.$store.state.user.userToken,
          payload
        );
        this.$router.push(`/logging-result?status=${result}`);
      } finally {
        this.isWaitingResult = false;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/scss/colors";
.cloud-carrier-loggin-upload {
  padding: 2rem 3.8rem;
}
.title {
  margin: 2.2rem 0 1.8rem;
}

.upload {
  &__label {
    color: $primary;
    font-weight: bold;
  }
  &__preview {
    width: 100%;
  }
}
.waiting {
  margin-top: 6.8rem;
}
</style>
