<template>
  <page-layout>
    <div class="e-invoice-logging">
      <divider-title title="電子發票" class="my-3"></divider-title>
      <div class="px-2">
        <p class="mb-1">請選擇登錄方式</p>
        <div class="choose mb-1">
          <img
            src="@/assets/images/e-invoice-button.png"
            class="image-button mr-2"
            width="120"
            height="120"
            alt=""
            @click="$router.push('/e-invoice-logging-photo')"
          />
          <img
            src="@/assets/images/manual-button.png"
            class="image-button"
            width="120"
            height="120"
            alt=""
            @click="$router.push('/e-invoice-logging-manual')"
          />
        </div>
        <p class="mb-3">*若發票類型錯誤，將不具抽獎及集點資格</p>
        <div class="mb-5">
          <button
            class="btn-primary-outline mb-1"
            @click="$router.push('/logging-main')"
          >
            重新選擇登錄發票類型
          </button>
          <button
            class="btn-primary-outline mb-1"
            @click="$router.push('/home-page')"
          >
            回到首頁
          </button>
        </div>
      </div>
      <divider-title title="電子發票範本如下"></divider-title>
      <div class="e-invoice-smaple px-2">
        <img
          src="@/assets/images/e-invoice-sample.png"
          alt=""
          width="246"
          height="366"
        />
      </div>
    </div>
  </page-layout>
</template>
<script>
import PageLayout from "@/components/layout/PageLayout";
import DividerTitle from "@/components/common/DividerTitle";
export default {
  components: {
    PageLayout,
    DividerTitle,
  },
};
</script>
<style lang="scss" scoped>
@import "@/scss/colors";
.e-invoice-logging {
  display: flex;
  flex-direction: column;
  padding: 2rem 3.8rem;
  text-align: center;
}
.choose {
  display: flex;
  justify-content: center;
  &__button {
    width: 120px;
    height: 120px;
    color: $white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: $secondary;
    border-radius: 5px;
    border: none;
  }
}
.image-button {
  cursor: pointer;
}
.e-invoice-smaple {
  margin-top: 2.8rem;
}
</style>