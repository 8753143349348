<template>
  <page-layout>
    <div class="logging-result">
      <divider-title class="my-3" :title="titleStatus"></divider-title>
      <div class="px-2">
        <img :src="iconSrc" alt="" width="93" height="93" class="mb-2" />
        <template v-if="status === statusList.SUCCESS">
          <p>審核成功</p>
          <p>恭喜完成登錄發票！</p>
        </template>
        <template v-else-if="status === statusList.ITEM_FAILED">
          <p>未購買活動品項</p>
          <p>您所登錄的發票未包含活動品項！</p>
          <p>請確認發票項目，或上傳之照片是否包含活動品項</p>
        </template>
        <template v-else-if="status === statusList.TIME_FAILED">
          <p>您所登錄的發票開立時間不符合活動期間</p>
          <p>活動期間：2022/4/1-2022/5/22</p>
          <p>詳情請參閱活動辦法</p>
        </template>
        <template v-else-if="status === statusList.PHOTO_FAILED">
          <p>可能因為下列原因導致照片辨識失敗</p>
          <p>請重新拍攝或改以傳統方式上傳登錄</p>
          <p>1. 拍攝角度不正</p>
          <p>2. 拍攝光源不足</p>
          <p>3. 發票紙本皺摺</p>
          <p>4. 發票文字不清</p>
        </template>
        <template v-else-if="status === statusList.REPEAT_FAILED">
          <p>發票重複登錄</p>
          <p>此發票號碼已成功登錄於後台，</p>
          <p>故無法重複登錄此發票，請查詢我的發票</p>
        </template>
        <template v-else-if="status === statusList.PENDING_DATA">
          <p>財政部尚無此發票明細，</p>
          <p>電子發票更新時間至多</p>
          <p>需48小時之審核時間，請您稍作等候！</p>
          <p>發票審核確認後，將獲得兌獎資格。</p>
        </template>
        <template v-else-if="status === statusList.PENDING_MANUAL">
          <p>感謝您的參與，雲端載具採人工審核，</p>
          <p>需5-7個工作天進行審核作業，</p>
          <p>請稍作等候</p>
        </template>
        <button
          v-if="status === statusList.PHOTO_FAILED"
          class="btn-primary-outline mt-3"
          @click="$router.push('/e-invoice-logging-photo')"
        >
          重新拍照上傳
        </button>
        <button
          v-if="status === statusList.PHOTO_FAILED"
          class="btn-primary-outline mt-1"
          @click="$router.push('/e-invoice-logging-manual')"
        >
          手動輸入發票資訊
        </button>
        <button
          v-if="status === statusList.PHOTO_FAILED"
          class="btn-primary mt-1"
          @click="$router.push('/logging-main')"
        >
          重新選擇登錄發票類型
        </button>
        <button
          v-if="status !== statusList.PHOTO_FAILED"
          class="btn-primary-outline mt-3"
          @click="$router.push('/logging-record')"
        >
          查詢我的發票
        </button>
        <button
          v-if="status !== statusList.PHOTO_FAILED"
          class="btn-primary mt-1"
          @click="$router.push('/home-page')"
        >
          回到首頁
        </button>
      </div>
    </div>
  </page-layout>
</template>
<script>
import PageLayout from "@/components/layout/PageLayout";
import DividerTitle from "@/components/common/DividerTitle";

const statusList = {
  SUCCESS: "SUCCESS",
  ITEM_FAILED: "ITEM_FAILED",
  TIME_FAILED: "TIME_FAILED",
  INFO_FAILED: "INFO_FAILED",
  PHOTO_FAILED: "PHOTO_FAILED",
  REPEAT_FAILED: "REPEAT_FAILED",
  PENDING_MANUAL: "PENDING_MANUAL",
  PENDING_DATA: "PENDING_DATA",
};
export default {
  components: {
    PageLayout,
    DividerTitle,
  },
  data() {
    return {
      statusList,
      status: "",
    };
  },
  mounted() {
    const { status } = this.$route.query;
    if (status === "success") this.status = this.statusList.SUCCESS;
    else if (status === "item-failed")
      this.status = this.statusList.ITEM_FAILED;
    else if (status === "time-failed")
      this.status = this.statusList.TIME_FAILED;
    else if (status === "info-failed")
      this.status = this.statusList.INFO_FAILED;
    else if (status === "photo-failed")
      this.status = this.statusList.PHOTO_FAILED;
    else if (status === "repeat-failed")
      this.status = this.statusList.REPEAT_FAILED;
    else if (status === "pending-data")
      this.status = this.statusList.PENDING_DATA;
    else if (status === "pending-manual")
      this.status = this.statusList.PENDING_MANUAL;
  },
  computed: {
    titleStatus() {
      if (this.status === this.statusList.SUCCESS) return "發票登錄成功";
      if (this.status === this.statusList.ITEM_FAILED) return "發票登錄失敗";
      if (this.status === this.statusList.TIME_FAILED) return "發票登錄失敗";
      if (this.status === this.statusList.INFO_FAILED) return "發票登錄失敗";
      if (this.status === this.statusList.PHOTO_FAILED) return "發票登錄失敗";
      if (this.status === this.statusList.REPEAT_FAILED) return "發票登錄失敗";
      if (this.status === this.statusList.PENDING_DATA) return "發票登錄待審核";
      if (this.status === this.statusList.PENDING_MANUAL)
        return "發票登錄待審核";
      return "";
    },
    iconSrc() {
      if (this.status === this.statusList.SUCCESS)
        return require("@/assets/images/check-circle.svg");
      if (this.status === this.statusList.ITEM_FAILED)
        return require("@/assets/images/close-circle.svg");
      if (this.status === this.statusList.TIME_FAILED)
        return require("@/assets/images/close-circle.svg");
      if (this.status === this.statusList.INFO_FAILED)
        return require("@/assets/images/close-circle.svg");
      if (this.status === this.statusList.PHOTO_FAILED)
        return require("@/assets/images/close-circle.svg");
      if (this.status === this.statusList.REPEAT_FAILED)
        return require("@/assets/images/close-circle.svg");
      if (this.status === this.statusList.PENDING_DATA)
        return require("@/assets/images/clock-circle.svg");
      if (this.status === this.statusList.PENDING_MANUAL)
        return require("@/assets/images/clock-circle.svg");
      return "";
    },
  },
};
</script>
<style lang="scss" scoped>
.logging-result {
  padding: 2rem 3.8rem;
  text-align: center;
}
</style>