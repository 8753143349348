import Vue from 'vue'
import VueRouter from 'vue-router'

import HomePage from '@/views/HomePage'
import CompaignInfo from '@/views/CompaignInfo'
import LoggingMain from '@/views/LoggingMain'
import EInvoiceLogging from '@/views/EInvoiceLogging'
import EInvoiceLoggingPhoto from '@/views/EInvoiceLoggingPhoto'
import EInvoiceLoggingManual from '@/views/EInvoiceLoggingManual'
import CloudCarrierLogging from '@/views/CloudCarrierLogging'
import CloudCarrierLoggingUpload from '@/views/CloudCarrierLoggingUpload'
import LoggingRecord from '@/views/LoggingRecord'
import LoggingResult from '@/views/LoggingResult'
import LuckyDraw from '@/views/LuckyDraw'
import PersonalInfo from '@/views/PersonalInfo'
import store from '@/store'

Vue.use(VueRouter)

const checkUserRegistered = (to, from, next) => {
  if (!store.state.user.hasRegistered) return next({
    path: '/personal-info',
    query: { redirect: to.fullPath }
  })
  return next()
}

const routes = [
  {
    path: '/compaign-info',
    name: 'CompaignInfo',
    component: CompaignInfo
  },
  {
    path: '/logging-main',
    name: 'LoggingMain',
    component: LoggingMain,
    beforeEnter: checkUserRegistered
  },
  {
    path: '/e-invoice-logging',
    name: 'EInvoiceLogging',
    component: EInvoiceLogging,
    beforeEnter: checkUserRegistered
  },
  {
    path: '/e-invoice-logging-photo',
    name: 'EInvoiceLoggingPhoto',
    component: EInvoiceLoggingPhoto,
    beforeEnter: checkUserRegistered
  },
  {
    path: '/e-invoice-logging-manual',
    name: 'EInvoiceLoggingManual',
    component: EInvoiceLoggingManual,
    beforeEnter: checkUserRegistered
  },
  {
    path: '/cloud-carrier-logging',
    name: CloudCarrierLogging,
    component: CloudCarrierLogging,
    beforeEnter: checkUserRegistered
  },
  {
    path: '/cloud-carrier-logging-upload',
    name: 'CloudCarrierLoggingUpload',
    component: CloudCarrierLoggingUpload,
    beforeEnter: checkUserRegistered
  },
  {
    path: '/logging-record',
    name: 'LoggingRecord',
    component: LoggingRecord,
  },
  {
    path: "/logging-result",
    name: 'LoggingResult',
    component: LoggingResult
  },
  {
    path: '/lucky-draw',
    name: 'LuckyDraw',
    component: LuckyDraw,
  },
  {
    path: '/personal-info',
    name: 'PersonalInfo',
    component: PersonalInfo
  },
  {
    path: '/home-page',
    name: 'HomePage',
    component: HomePage
  }
]

const router = new VueRouter({
  mode: 'history',
  routes
})

export default router
