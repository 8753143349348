<template>
  <page-layout>
    <div v-if="!isWaitingResult" class="e-invoice-logging-manual">
      <divider-title title="電子發票手動輸入" class="title"></divider-title>
      <div class="px-2">
        <input-field
          type="text"
          class="mb-1"
          label="發票號碼"
          placeholder="例：AB12345678"
          :errorMsg="isInvoiceNumberError ? '輸入格式為英文2碼數字8碼' : ''"
          v-model="invoiceNumber"
        ></input-field>
        <input-field
          type="text"
          class="mb-1"
          label="發票隨機碼"
          placeholder="5678"
          :errorMsg="isRandomCodeError ? '輸入格式為4碼數字' : ''"
          v-model="randomCode"
        ></input-field>
        <input-field
          type="date"
          class="mb-3"
          label="發票日期"
          :errorMsg="isInvoiceDateError ? '請選擇發票日期' : ''"
          v-model="invoiceDate"
        ></input-field>
        <button
          :disabled="
            isInvoiceNumberError || isInvoiceDateError || isRandomCodeError
          "
          class="btn-primary"
          @click="uploadEInvoice"
        >
          送出登錄資料
        </button>
      </div>
    </div>
    <div class="waiting" v-if="isWaitingResult">
      <loading-circle title="發票辨識中...請稍候"></loading-circle>
    </div>
  </page-layout>
</template>
<script>
import PageLayout from "@/components/layout/PageLayout";
import DividerTitle from "@/components/common/DividerTitle";
import InputField from "@/components/common/InputField";
import LoadingCircle from "@/components/common/LoadingCircle";
import { verifyInvoice } from "@/utils";
import { uploadEInvoice } from "@/api/invoice";
export default {
  components: {
    PageLayout,
    DividerTitle,
    InputField,
    LoadingCircle,
  },
  data() {
    return {
      invoiceNumber: "",
      randomCode: "",
      invoiceDate: "",
      isWaitingResult: false,
    };
  },
  computed: {
    isInvoiceNumberError() {
      return !verifyInvoice(this.invoiceNumber);
    },
    isRandomCodeError() {
      return !/^\d{4}$/.test(this.randomCode);
    },
    isInvoiceDateError() {
      return this.invoiceDate.length === 0;
    },
  },
  methods: {
    async uploadEInvoice() {
      const payload = {
        invoiceNumber: this.invoiceNumber,
        invoiceDate: this.invoiceDate,
        randomCode: this.randomCode,
      };
      try {
        this.isWaitingResult = true;
        const result = await uploadEInvoice(
          this.$store.state.user.userToken,
          payload
        );
        this.$router.push(`/logging-result?status=${result}`);
      } finally {
        this.isWaitingResult = false;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.e-invoice-logging-manual {
  padding: 2rem 3.8rem;
}
.title {
  margin: 2.2rem 0 1.8rem;
}
.waiting {
  margin-top: 6.8rem;
}
</style>