
<template>
  <div class="page-layout">
    <div class="scroll" :style="gradientBackground">
      <header>
        <img
          class="page-layout__image"
          src="@/assets/images/banner.png"
          alt=""
          @load="setBackgroundPosition"
        />
      </header>
      <main class="main">
        <slot></slot>
      </main>
    </div>
    <footer class="footer">
      <img
        class="page-layout__hover-image"
        :style="{ height: '4vh' }"
        src="@/assets/images/footer-warning.png"
        alt=""
      />
      <img
        class="page-layout__image"
        :style="{ height: '10vh' }"
        src="@/assets/images/footer-banner.png"
        alt=""
      />
    </footer>
  </div>
</template>
<script>
export default {
  data() {
    return {
      headerImageHeight: 0,
    };
  },
  methods: {
    setBackgroundPosition(e) {
      this.headerImageHeight = e.target.height;
    },
  },
  computed: {
    gradientBackground() {
      return {
        background: `linear-gradient(180deg, #eeeeef 0px, #eeeeef ${this.headerImageHeight}px, #ffffff 100%)`,
      };
    },
  },
};
</script>
<style lang="scss" scoped>
$footer-height: 10vh;
.page-layout {
  height: 100%;
  display: flex;
  flex-direction: column;
  > .scroll {
    flex: 1;
    overflow: auto;
    max-height: calc(100% - $footer-height);
  }
  > .footer {
    height: $footer-height;
    margin-top: auto;
  }
  &__image {
    display: block; // remove gap causing by inline-block item
    width: 100%;
  }
  &__hover-image {
    position: absolute;
    width: 100%;
    object-fit: cover;
    object-position: left top;
    transform: translateY(-100%);
    pointer-events: none;
  }
}
</style>