const state = {
    userToken: '',
    hasRegistered: false
}
const getters = {}
const actions = {
    setToken({ commit }, token) {
        commit('SET_TOKEN', token)
    },
    setHasRegistered({ commit }, token) {
        commit('SET_HAS_REGISTERED', token)
    }
}
const mutations = {
    SET_TOKEN(state, token) {
        state.userToken = token
    },
    SET_HAS_REGISTERED(state, bool) {
        state.hasRegistered = bool
    }
}

export default {
    state,
    getters,
    actions,
    mutations,
    namespaced: true
}
