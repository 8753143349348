import { api } from '@/api'
import { logError } from '@/api/firebase'

export const markLogin = async (userToken) => {
    const urlEnd = '/api/front/user/login'
    const body = {
        'user_token': userToken
    }
    try {
        await api.post(urlEnd, body)
    } catch (err) {
        console.error(err)
        logError({
            payload: body,
            error: err,
            apiUrl: urlEnd,
            method: 'post'
        })
        return Promise.reject('紀錄首次登入發生錯誤')
    }

}

export const checkRegistered = async (userToken) => {
    const urlEnd = '/api/front/user/checkInfo'
    const body = {
        'user_token': userToken
    }
    try {
        const response = await api.post(urlEnd, body)
        return response.data.data.is_member
    } catch (err) {
        console.error(err)
        logError({
            payload: body,
            error: err,
            apiUrl: urlEnd,
            method: 'post'
        })
        // return Promise.reject('檢查是否填過資料發生錯誤')
        return false
    }
}


export const registerUser = async (userToken, payload) => {
    const urlEnd = '/api/front/user/registe'
    const body = {
        "user_token": userToken,
        "first_name": payload.name,
        "email": payload.email,
        "birthday": payload.birthday,
        "cell_phone": payload.cellphone,
        "gender": Number(payload.gender)
    }
    try {
        await api.post(urlEnd, body)
    } catch (err) {
        console.error('err')
        logError({
            payload: body,
            error: err,
            apiUrl: urlEnd,
            method: 'post'
        })
        return Promise.reject('填寫會員資料發生錯誤')
    }
}