<template>
  <page-layout>
    <div v-if="!isWaitingResult" class="e-invoice-logging-photo">
      <divider-title title="電子發票影像辨識" class="my-3"></divider-title>
      <div class="upload px-2">
        <p class="upload__text">上傳發票照片</p>
        <button class="btn-primary mb-1" @click="$refs.inputFile.click()">
          選擇發票照片
        </button>
        <input
          type="file"
          hidden
          ref="inputFile"
          accept="image/*"
          @change="chooseFile"
        />
        <img
          v-if="imageBase64.length"
          class="upload__preview my-1"
          :src="imageBase64"
          alt=""
        />
        <p class="mb-4">
          ＊上傳之照片須清晰顯示「發票號碼、發票隨機碼、發票日期」，若資訊不足導致無法判斷，將需重新上傳進行審核
        </p>
        <button
          class="btn-primary-outline"
          :disabled="!imageBase64.length"
          @click="identifyEInvoice"
        >
          送出登錄資料
        </button>
      </div>
    </div>
    <div class="waiting" v-if="isWaitingResult">
      <loading-circle title="發票辨識中...請稍候"></loading-circle>
    </div>
  </page-layout>
</template>
<script>
import PageLayout from "@/components/layout/PageLayout";
import DividerTitle from "@/components/common/DividerTitle";
import LoadingCircle from "@/components/common/LoadingCircle";
import { getBase64 } from "@/utils";
import { identifyEInvoice } from "@/api/invoice";
export default {
  components: {
    PageLayout,
    DividerTitle,
    LoadingCircle,
  },
  data() {
    return {
      imageBase64: "",
      isWaitingResult: false,
    };
  },
  methods: {
    chooseFile(e) {
      if (e.target.files.length === 0) {
        this.imageBase64 = "";
        return;
      }
      const imageFile = e.target.files[0];
      getBase64(imageFile).then((base64) => (this.imageBase64 = base64));
    },
    async identifyEInvoice() {
      try {
        this.isWaitingResult = true;
        const result = await identifyEInvoice(
          this.$store.state.user.userToken,
          this.imageBase64
        );
        this.$router.push(`/logging-result?status=${result}`);
      } finally {
        this.isWaitingResult = false;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.e-invoice-logging-photo {
  padding: 2rem 3.8rem;
}
.upload {
  &__text {
    font-weight: 700;
  }
  &__preview {
    width: 100%;
  }
}
.waiting {
  margin-top: 6.8rem;
}
</style>